var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"bg-black-0 rounded pr-16 pr-sm-32 mx-auto"},[_c('b-col',{staticClass:"pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden",attrs:{"cols":"12"}},[_c('h2',[_vm._v("Tambah Tipe Inventory")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ul',[_c('li',[_c('div',{staticClass:"d-block mb-3"},[_c('label',[_vm._v("Nama Barang")]),_c('b-form-input',{attrs:{"type":"text","state":_vm.v$.typeinventory.nama.$dirty
                ? !_vm.v$.typeinventory.nama.$error
                : null,"placeholder":"Masukkan Nama Barang"},model:{value:(_vm.typeinventory.nama),callback:function ($$v) {_vm.$set(_vm.typeinventory, "nama", $$v)},expression:"typeinventory.nama"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.typeinventory.nama.$dirty
                ? !_vm.v$.typeinventory.nama.$error
                : null}},[_vm._v(" "+_vm._s(_vm.errorMessages.nama)+" ")])],1)]),_c('li',[_c('div',{staticClass:"d-block mb-3"},[_c('label',[_vm._v("Kode Barang")]),_c('b-form-input',{attrs:{"type":"text","state":_vm.v$.typeinventory.kode.$dirty
                ? !_vm.v$.typeinventory.kode.$error
                : null,"placeholder":"Masukkan Kode Barang"},model:{value:(_vm.typeinventory.kode),callback:function ($$v) {_vm.$set(_vm.typeinventory, "kode", $$v)},expression:"typeinventory.kode"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.typeinventory.kode.$dirty
                ? !_vm.v$.typeinventory.kode.$error
                : null}},[_vm._v(" "+_vm._s(_vm.errorMessages.kode)+" ")])],1)]),_c('li',[_c('div',{staticClass:"d-block mb-3"},[_c('label',[_vm._v("Jumlah Maksimum")]),_c('b-form-input',{attrs:{"type":"number","state":_vm.v$.typeinventory.maksimum.$dirty
                ? !_vm.v$.typeinventory.maksimum.$error
                : null,"placeholder":"Masukkan Jumlah Maksimum"},model:{value:(_vm.typeinventory.maksimum),callback:function ($$v) {_vm.$set(_vm.typeinventory, "maksimum", $$v)},expression:"typeinventory.maksimum"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.typeinventory.maksimum.$dirty
                ? !_vm.v$.typeinventory.maksimum.$error
                : null}},[_vm._v(" "+_vm._s(_vm.errorMessages.maksimum)+" ")])],1)]),_c('li',[_c('div',{staticClass:"d-block mb-3"},[_c('label',[_vm._v("Jumlah Minimum")]),_c('b-form-input',{attrs:{"type":"number","state":_vm.v$.typeinventory.minimum.$dirty
                ? !_vm.v$.typeinventory.minimum.$error
                : null,"placeholder":"Masukkan Jumlah Minimum"},model:{value:(_vm.typeinventory.minimum),callback:function ($$v) {_vm.$set(_vm.typeinventory, "minimum", $$v)},expression:"typeinventory.minimum"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.typeinventory.minimum.$dirty
                ? !_vm.v$.typeinventory.minimum.$error
                : null}},[_vm._v(" "+_vm._s(_vm.errorMessages.minimum)+" ")])],1)]),_c('li',[_c('div',{staticClass:"d-block mb-3"},[_c('label',[_vm._v("Kategori")]),_c('v-select',{attrs:{"options":_vm.categoryInventorys.map((v) => ({
                label: v.category_name,
                value: v.category_name,
              })),"reduce":(option) => option.value,"state":_vm.v$.typeinventory.category.$dirty
                ? !_vm.v$.typeinventory.category.$error
                : null},model:{value:(_vm.typeinventory.category),callback:function ($$v) {_vm.$set(_vm.typeinventory, "category", $$v)},expression:"typeinventory.category"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.typeinventory.category.$dirty
                ? !_vm.v$.typeinventory.category.$error
                : null}},[_vm._v(" "+_vm._s(_vm.errorMessages.category)+" ")])],1)])])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ul',[_c('li',[_c('div',{staticClass:"d-block mb-3"},[_c('label',[_vm._v("Vendor")]),_c('v-select',{attrs:{"options":_vm.vendors.map((v) => ({ label: v.name, value: v.name })),"reduce":(option) => option.value,"state":_vm.v$.typeinventory.vendor.$dirty
                ? !_vm.v$.typeinventory.vendor.$error
                : null},model:{value:(_vm.typeinventory.vendor),callback:function ($$v) {_vm.$set(_vm.typeinventory, "vendor", $$v)},expression:"typeinventory.vendor"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.typeinventory.vendor.$dirty
                ? !_vm.v$.typeinventory.vendor.$error
                : null}},[_vm._v(" "+_vm._s(_vm.errorMessages.vendor)+" ")])],1)]),_c('li',[_c('div',{staticClass:"d-block mb-3"},[_c('label',[_vm._v("Harga")]),_c('b-form-input',{attrs:{"type":"number","state":_vm.v$.typeinventory.harga.$dirty
                ? !_vm.v$.typeinventory.harga.$error
                : null,"placeholder":"Masukkan Harga"},model:{value:(_vm.typeinventory.harga),callback:function ($$v) {_vm.$set(_vm.typeinventory, "harga", $$v)},expression:"typeinventory.harga"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.typeinventory.harga.$dirty
                ? !_vm.v$.typeinventory.harga.$error
                : null}},[_vm._v(" "+_vm._s(_vm.errorMessages.harga)+" ")])],1)]),_c('li',[_c('div',{staticClass:"d-block mb-3"},[_c('label',[_vm._v("Catatan")]),_c('b-form-textarea',{attrs:{"placeholder":"Masukkan Catatan","rows":4},model:{value:(_vm.typeinventory.deskripsi),callback:function ($$v) {_vm.$set(_vm.typeinventory, "deskripsi", $$v)},expression:"typeinventory.deskripsi"}})],1)])])]),_c('b-col',{staticClass:"d-flex justify-content-end mb-3"},[_c('b-button',{attrs:{"variant":"danger-1 mx-2"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Batal ")]),_c('b-button',{attrs:{"variant":"success-1","type":"submit","loading":_vm.isBusy},on:{"click":_vm.storeType}},[(_vm.isBusy)?_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}):_c('span',[_vm._v("Simpan")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }