<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-auto">
    <b-col cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Tambah Tipe Inventory</h2>
    </b-col>
    <b-col cols="6">
      <ul>
        <li>
          <div class="d-block mb-3">
            <label>Nama Barang</label>
            <b-form-input
              v-model="typeinventory.nama"
              type="text"
              :state="
                v$.typeinventory.nama.$dirty
                  ? !v$.typeinventory.nama.$error
                  : null
              "
              placeholder="Masukkan Nama Barang"
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.nama.$dirty
                  ? !v$.typeinventory.nama.$error
                  : null
              "
            >
              {{ errorMessages.nama }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Kode Barang</label>
            <b-form-input
              v-model="typeinventory.kode"
              type="text"
              :state="
                v$.typeinventory.kode.$dirty
                  ? !v$.typeinventory.kode.$error
                  : null
              "
              placeholder="Masukkan Kode Barang"
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.kode.$dirty
                  ? !v$.typeinventory.kode.$error
                  : null
              "
            >
              {{ errorMessages.kode }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Jumlah Maksimum</label>
            <b-form-input
              v-model="typeinventory.maksimum"
              type="number"
              :state="
                v$.typeinventory.maksimum.$dirty
                  ? !v$.typeinventory.maksimum.$error
                  : null
              "
              placeholder="Masukkan Jumlah Maksimum"
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.maksimum.$dirty
                  ? !v$.typeinventory.maksimum.$error
                  : null
              "
            >
              {{ errorMessages.maksimum }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Jumlah Minimum</label>
            <b-form-input
              v-model="typeinventory.minimum"
              type="number"
              :state="
                v$.typeinventory.minimum.$dirty
                  ? !v$.typeinventory.minimum.$error
                  : null
              "
              placeholder="Masukkan Jumlah Minimum"
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.minimum.$dirty
                  ? !v$.typeinventory.minimum.$error
                  : null
              "
            >
              {{ errorMessages.minimum }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Kategori</label>
            <v-select
              v-model="typeinventory.category"
              :options="
                categoryInventorys.map((v) => ({
                  label: v.category_name,
                  value: v.category_name,
                }))
              "
              :reduce="(option) => option.value"
              :state="
                v$.typeinventory.category.$dirty
                  ? !v$.typeinventory.category.$error
                  : null
              "
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.category.$dirty
                  ? !v$.typeinventory.category.$error
                  : null
              "
            >
              {{ errorMessages.category }}
            </b-form-invalid-feedback>
          </div>
        </li>
      </ul>
    </b-col>
    <b-col cols="6">
      <ul>
        <li>
          <div class="d-block mb-3">
            <label>Vendor</label>
            <v-select
              v-model="typeinventory.vendor"
              :options="vendors.map((v) => ({ label: v.name, value: v.name }))"
              :reduce="(option) => option.value"
              :state="
                v$.typeinventory.vendor.$dirty
                  ? !v$.typeinventory.vendor.$error
                  : null
              "
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.vendor.$dirty
                  ? !v$.typeinventory.vendor.$error
                  : null
              "
            >
              {{ errorMessages.vendor }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Harga</label>
            <b-form-input
              v-model="typeinventory.harga"
              type="number"
              :state="
                v$.typeinventory.harga.$dirty
                  ? !v$.typeinventory.harga.$error
                  : null
              "
              placeholder="Masukkan Harga"
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.harga.$dirty
                  ? !v$.typeinventory.harga.$error
                  : null
              "
            >
              {{ errorMessages.harga }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Catatan</label>
            <b-form-textarea
              v-model="typeinventory.deskripsi"
              placeholder="Masukkan Catatan"
              :rows="4"
            />
          </div>
        </li>
      </ul>
    </b-col>
    <b-col class="d-flex justify-content-end mb-3">
      <b-button variant="danger-1 mx-2" @click="$router.go(-1)">
        Batal
      </b-button>
      <b-button
        variant="success-1"
        type="submit"
        :loading="isBusy"
        @click="storeType"
      >
        <b-spinner small label="Small Spinner" v-if="isBusy" />
        <span v-else>Simpan</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormTextarea,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import manageCategoryInventoryAPI from "../../../../../api/managecategoryinventory/manageCategoryInventoryAPI";
import manageVendorAPI from "../../../../../api/managevendor/manageVendorAPI";
import manageInventoryAPI from "../../../../../api/manageinventory/manageInventoryAPI";

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "AddTypeInventory",
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      typeinventory: {
        nama: "",
        kode: "",
        maksimum: "",
        minimum: "",
        category: null,
        vendor: null,
        harga: "",
        deskripsi: "",
      },
      errorMessages: {},
      categoryInventorys: [],
      vendors: [],
      isBusy: false,
    };
  },
  validations() {
    return {
      typeinventory: {
        nama: { required },
        kode: { required },
        maksimum: { required },
        minimum: { required },
        category: { required },
        vendor: { required },
        harga: { required },
      },
    };
  },
  methods: {
    async fetchData() {
      try {
        const [categoryResponse, vendorResponse] = await Promise.all([
          manageCategoryInventoryAPI.getAll(),
          manageVendorAPI.getAll(),
        ]);

        this.categoryInventorys = categoryResponse.data.data.data;
        this.vendors = vendorResponse.data.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.$bvToast.toast("Gagal mengambil data", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },

    async storeType() {
      try {
        this.isBusy = true;
        const formData = new FormData();
        formData.append("item_name", this.typeinventory.nama);
        formData.append("item_code", this.typeinventory.kode);
        formData.append("maximum_quantity", this.typeinventory.maksimum);
        formData.append("minimum_quantity", this.typeinventory.minimum);
        formData.append("category", this.typeinventory.category);
        formData.append("vendor", this.typeinventory.vendor);
        formData.append("price", this.typeinventory.harga);
        formData.append("description", this.typeinventory.deskripsi);

        const response = await manageInventoryAPI.addTypeInventory(formData);

        this.$bvToast.toast(response.data.message, {
          title: "Success",
          variant: "success",
          solid: true,
        });

        this.$router.push({ name: "listinventorygoods" });
      } catch (error) {
        console.error("Error storing type:", error);
        this.$bvToast.toast("Gagal menambahkan data", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>
